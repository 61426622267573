<script>
import {mapActions} from "vuex";

export default {
  name: "couponCard",
  props:{
    item:{
      percentage:{
        type:String
      },
      description:{
        type:String
      },
      startDate:{
        type:String
      },
      endDate:{
        type:String
      },
      used:{
        type:Boolean
      },
      code:{
        type:String
      }
    }
  },
  methods: {
    ...mapActions("general", ["copyText"]),
    async copyToClipboard(value) {
      await this.copyText({text: value});
      this.$notify({
        title: "کپی شد",
        text: "",
        type: "success",
      });
    },
  }
}
</script>

<template>
  <div class="coupon-item main-card h-100">
    <CRow class="w-100">
      <CCol md="auto" class="flex-center">
        <h1 class="col-auto percentage-text">
          <span class="font-weight-bold">{{ item.percentage }}</span>
          <font-awesome-icon icon="percentage" size="xs"/>
        </h1>
      </CCol>
      <CCol col>
        <div class="d-flex justify-content-evenly flex-column w-100 h-100 gap-10">
          <div>
            <p>{{ item.description }}</p>
          </div>
          <h6 v-if="!!item.startDate" class="text-success">تاریخ شروع {{ item.startDate }}</h6>
          <h6 v-if="!!item.endDate" class="text-danger">تاریخ پایان {{ item.endDate }}</h6>
        </div>
      </CCol>
      <CCol col="auto">
        <hr style="height: 100%" class="h-100 vertical"/>
      </CCol>
      <CCol col="auto" class="flex-center gap-10">
        <span>{{ item.state ? item.used : item.code }}</span>
        <CButton v-if="!item.state" color="primary" @click="()=>copyToClipboard(item.code)">
          <i class="fa fa-files-o" aria-hidden="true"></i>
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>

.coupon-item {
  width: 100%;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

</style>
