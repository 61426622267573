<template>
  <div>
    <body-with-header ads title="جشنواره و کد تخفیف">
      <CRow class="h-100">
        <vue-element-loading
          :active="loading"
          :text="loadingText"
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <CCol md="12">
          <CTabs fade variant="tabs">
            <CTab active>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span class="coupon-count chip flex-center bg-secondary text-white">{{ festivalList.length }}</span>
                  <span>جشنواره های سامانه</span>
                  <font-awesome-icon icon="gift"/>
                </div>
              </template>
              <div class="tab-body-card">
                <no-data v-if="festival_emptyTitle" :show="festival_emptyTitle" message="در حال حاضر جشنواره ی فعالی وجود ندارد."
                         class="couponEmptyTitle"/>
                <div v-else class="festivalList vertical-g-10">
                  <div class="d-flex">
                    <CAlert class="mb-0" color="info">
                      <font-awesome-icon icon="info" class="ml-2"/>
                      <span>جشنواره های فعال سبز رنگ هستند</span>
                    </CAlert>
                  </div>
                  <CRow class="gap-10">
                    <CCol
                      md="auto"
                      v-for="(item, index) in festivalList"
                      :key="index"
                      class="festivalItem main-card"
                    >
                      <div class="festivalImage">
                        <h5>{{ item.name }}</h5>
                        <img :src="getSrc(item.image)" alt="تصویر مشکل دارد"/>
                      </div>
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="festivalInfo">
                          <p>{{ item.description }}</p>
                          <h6 class="text-success">تاریخ شروع {{ item.startDate }}</h6>
                          <h6 class="text-danger">تاریخ پایان {{ item.endDate }}</h6>
                        </div>
                        <h1 class="col-auto percentage-text">
                          <span class="font-weight-bold">{{ item.percentage }}</span>
                          <font-awesome-icon icon="percentage" size="xs"/>
                        </h1>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span class="coupon-count chip flex-center bg-secondary text-white">{{ publicCodeList.length }}</span>
                  <span>کدهای تخفیف سامانه</span>
                  <font-awesome-icon icon="percentage"/>
                </div>
              </template>
              <div class="tab-body-card">
                <no-data v-if="publicCode_emptyTitle" :show="publicCode_emptyTitle" message="هیچ کد تخفیفی ثبت نشده است"
                         class="couponEmptyTitle"/>
                <div v-else class="publicCodeList">
                  <div class="d-grid grid-c-2 gap-20 p-3">
                    <div class="d-flex" style="grid-column:1 / 2 span">
                      <CAlert class="mb-0" color="info">
                        <font-awesome-icon icon="info" class="ml-2"/>
                        <span>کدهای فعال با رنگ سبز مشخص می شوند</span>
                      </CAlert>
                    </div>
                    <coupon-card v-for="(item, index) in publicCodeList" :item="item" :key="index"/>
                  </div>
                </div>
              </div>
            </CTab>
            <CTab>
              <template #title>
                <div class="y-center-g-10 justify-content-center">
                  <span class="coupon-count chip flex-center bg-secondary text-white">{{ privateCodeList.length }}</span>
                  <span>کدهای تخفیف اختصاصی کیف پول شما</span>
                  <font-awesome-icon icon="wallet"/>
                </div>
              </template>
              <div class="tab-body-card">
                <no-data v-if="privateCode_emptyTitle" :show="privateCode_emptyTitle"
                         message="کیف پول شما هیچ کد تخفیفی ندارد" class="couponEmptyTitle"/>
                <div v-else class="privateCodeList">
                  <div class="d-grid grid-c-2 gap-20 p-3">
                    <div class="d-flex" style="grid-column:1 / 2 span">
                      <CAlert class="mb-0" color="info">
                        <font-awesome-icon icon="info" class="ml-2"/>
                        <span>کدهای فعال با رنگ سبز مشخص می شوند</span>
                      </CAlert>
                    </div>
                    <coupon-card v-for="(item, index) in privateCodeList" :item="item" :key="index"/>
                  </div>
                </div>
              </div>
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </body-with-header>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {apiUrlRoot} from "../../../constants/config";
import NoData from "../../../components/no-data.vue";
import CouponCard from "../../../components/couponCard.vue";
import BodyWithHeader from "../../../components/bodyWithHeader.vue";

export default {
  components: {BodyWithHeader, CouponCard, NoData},
  data() {
    return {
      loading: false,
      loadingText: "",
      festival_emptyTitle: false,
      publicCode_emptyTitle: false,
      privateCode_emptyTitle: false,
      festivalList: [],
      privateCodeList: [],
      publicCodeList: []
    };
  },
  computed: {},
  methods: {
    ...mapActions("financialUser", [
      "DiscountsGetAllFestival",
      "DiscountsGetAllPublicOfferCode",
      "DiscountsGetAllCustomOfferCode",
    ]),
    ...mapActions("general", ["copyText"]),
    async copyToClipboard(value) {
      await this.copyText({text: value});
      this.$notify({
        title: "کپی شد",
        text: "",
        type: "success",
      });
    },
    getSrc(src) {
      return `${apiUrlRoot}offerFestivel/${src}`;
    },
    async GetAllFestival() {
      this.loading = true;
      this.loadingText = "دریافت لیست جشنواره ها...";
      let result = await this.DiscountsGetAllFestival({});
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.festivalList.push({
              name: value.name,
              image: value.image,
              percentage: value.percentage,
              description: value.description,
              startDate: value.startDate,
              endDate: value.endDate,
            });
          });
        } else {
          this.festival_emptyTitle = true;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      await this.GetAllPublicOfferCode();
    },
    async GetAllPublicOfferCode() {
      this.loadingText = "دریافت لیست کد های تخفیف ...";
      let result = await this.DiscountsGetAllPublicOfferCode({})
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.publicCodeList.push({
              percentage: value.percentage,
              code: value.code,
              description: value.description,
              startDate: value.startDate,
              endDate: value.endDate,
            });
          });
        } else {
          this.publicCode_emptyTitle = true;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      await this.GetAllCustomOfferCode();
    },
    async GetAllCustomOfferCode() {
      this.loadingText = "دریافت لیست کدهای تخفیف کیف پول شما...";
      let result = await this.DiscountsGetAllCustomOfferCode({});
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.privateCodeList.push({
              percentage: value.percentage,
              code: value.code,
              description: value.description,
              used: value.used,
              state: value.state,
            });
          });
        } else {
          this.privateCode_emptyTitle = true;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.GetAllFestival();
  },
};
</script>

<style>
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background-color: #fff !important;
}
.chip{
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 5px;
}
</style>
<style scoped>
.festivalList {
  width: 100%;
  overflow-y: auto;
  min-height: 40vh;
}

.festivalItem {
  display: flex;
  height: 15vh;
}

.festivalImage {
  flex-basis: 50%;
  height: 100%;
  position: relative;
}

.festivalImage h5 {
  position: absolute;
  background-image: linear-gradient(270deg, white, #ffffff00);
  width: 95%;
  top: 5%;
  padding-right: 9px;
  margin-right: 3px;
  right: 0;
}

.festivalImage img {
  height: 100%;
  width: 100%;
}

.festivalInfo {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.festivalInfo h1 {
  text-align: center;
  font-size: 1.5rem;
}

.publicCodeList {
  width: 100%;
  min-height: 40vh;
  overflow-y: auto;
  padding: 10px;
}

.privateCodeList {
  width: 100%;
  min-height: 40vh;
  overflow-y: auto;
  padding: 10px;
}

.couponEmptyTitle {
  width: 100%;
  height: 10vh;
  text-align: center;
  padding-top: 4%;
  color: #979797;
  font-size: 1rem;
}

.percentage-text {
  font-size: 60px;
}
</style>
